import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useUserStore } from '../../../state';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(theme => ({
  padded: {
    paddingBottom: 16,
  },
}));

/**
 * REACT COMPONENT: displays the number of seats for a paying user broken down into the 
 * number of purchased seats, the number of seats currently in use and the number of purchased seats 
 * remaining not in use.
 */
const SeatsWidget = observer(() => {
  const classes = useStyles();
  const userStore = useUserStore();

  // local state
  const [renderWidget, setRenderWidget] = React.useState(false);

  // populate local state
  React.useEffect(() => {
    if (userStore.currentUser.subscriptionType === "edu") {
      setRenderWidget(true);
      // ensure global state is populated to render
      if (!userStore.seatsPurchased) { userStore.getSeatsPurchased(); }
      if (!userStore.seatsUsed) { userStore.getSeatsUsed(); }
    }
    return () => {
      setRenderWidget(false);
    }
  }, [userStore.currentUser]);

  return (
    <>
      {renderWidget &&
        <div>
          <Typography variant={'h2'}>Seats Breakdown</Typography>
          <Typography>Total Purchased Seats: {userStore.seatsPurchased}</Typography>
          <Typography>Total Used Seats: {userStore.seatsUsed}</Typography>
          <Typography className={classes.padded}>Total Seats Remaining: {userStore.seatsPurchased - userStore.seatsUsed}</Typography>
          <Typography className={classes.padded}>
            These figures are a breakdown of all purchased subscriptions and active clubs 
            over your whole user account.
          </Typography>
        </div>
      }
    </>
  )
});

export default SeatsWidget;