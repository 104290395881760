// import { login } from "../api/auth";
import {  userFromToken } from "../api/auth";

import { makeAutoObservable, observable } from "mobx";
import {
  updateUser,
  updateUserEmail,
  validateUnique,
  updatePassword,
  fetchSeats,
  removeSeatsApi
} from '../api/user'

// helper functions

/**
 * Checks if a given seat or subscription is valid based on the either 12mth or 3mth 
 * expiry date depending on which side of the 11th Feb 2022 NZ time change over date in 
 * subscription length that also effects seats created by these subscriptions.
 * 
 * @param {string} dateCreated the date the subscription or seat was created.
 * @param {string} dateToCheck the date the subscription was updated or the date the seat was created.
 * @returns {boolean} true if valid, false if invalid.
 * 
 * NOTE: logic re 12mth subs can be removed after 12th Feb 2023 as all historical 
 * subscriptions will be expired by this date
 */
const isValid = (dateCreated, dateToCheck) => {
  // define constants for use in calculations

  /** change of date in milliseconds */
  const milliseconds_changeOverDate = 1644591600000;

  /** a year in milliseconds */
  const milliseconds_1_Year = 31556952000;

  /** three months in milliseconds */
  const milliseconds_3_months = 7889238000;

  /** current date in milliseconds for comparison */
  const milliseconds_currentDate = Date.now();

  /** passed dateCreated param converted to milliseconds */
  const milliseconds_dateCreated = new Date(dateCreated).getTime();

  /** passed dateToCheck param converted to milliseconds */
  const milliseconds_dateToCheck = new Date(dateToCheck).getTime();

  // check if uses current 3mth sub length or historical 12mth length.
  if (milliseconds_dateCreated >= milliseconds_changeOverDate) {
    // calculate expiry date base on 3mth length.
    const expiryDate = milliseconds_dateToCheck + milliseconds_3_months;

    // check if valid or expired.
    if (expiryDate > milliseconds_currentDate ) {
      // is still valid return true.
      return true;
    } else {
      // has expired return false.
      return false;
    }
  } else {
    // calculate expiry date base on 12mth length.
    const expiryDate = milliseconds_dateToCheck + milliseconds_1_Year;

    // check if valid or expired.
    if (expiryDate > milliseconds_currentDate ) {
      // is still valid return true.
      return true;
    } else {
      // has expired return false.
      return false;
    }
  }
};

// main exported class

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  // <--- Observables --->
  currentUser = {
    id: null,
    username: '',
    subscriptionType: '',
    firstName: '',
    lastName: '',
    createdAt: '',
    updatedAt: '',
    type: '',
    uploadUsage: 0,
    lessonRecords: {},
    members: [],
    statistics: {},
    features: ""
  }

  loadingUser = false
  loadingUserErrors = null

  changingPassword = false;
  changePasswordSuccess = undefined;
  changePasswordError = undefined

  profile = {}

  subscription = {}
  activeSeats = []

  /** number of valid purchased seats */
  seatsPurchased = undefined;

  /** number of currently used purchased seats */
  seatsUsed = undefined;

  /** the number of purchased seats remaining taking into account all seats currently in use */
  // seatsRemaining = undefined;

  // <--- Actions --->
  populateProfile() {
    this.profile = new Profile(this.currentUser)
  }

  pushSeat(seat) {
    this.activeSeats.push(seat)
  }
  
  getSeatsPurchased() {
    this.seatsPurchased = this.currentUser.subscriptions.reduce((acc,sub)=>{
      // check if valid or expired
      const valid = isValid(sub.createdAt, sub.updatedAt);
  
      // if valid add to accumulator and return if not return accumulator without addition.
      if (valid) { 
        // check if first addition to accumulator
        if (!!acc) {
          return acc += sub.seats;
        } else {
          return sub.seats;
        }
      } else {
        return acc;
      }
    },0);
  };

  /**
   * Calculates the number of seats user by taking the activeSeats data and checking it 
   * valid and not expired as well as removing duplicate records.
   * 
   * Note if no activeSeats data is available this will 
   */
  async getSeatsUsed() {
    if (!this.activeSeats.length) { 
      const {data} = await fetchSeats(this.currentUser.subscriptions[0].id);
      this.activeSeats = data;
    };

    // filter out invalid/expired seats.
    const validSeats = this.activeSeats.reduce((acc,seat)=>{
      // check if valid or expired
      const valid = isValid(seat.createdAt, seat.createdAt);
        // if valid add to accumulator and return if not return accumulator without addition.
        if (valid) { 
          // check if first addition to accumulator
          if (acc.length) {

            return [...acc, seat];
          } else {
            return [seat]; 
          }
        } else {
          return acc;
        }
      },[]);

      // filter out any duplicate records caused be previously identified and resolved bugs.
      // NOTE: duplicates are not exact duplicates as can be created at different times so createdAt 
      // and updatedAt dates are of no use in this calculation as is exact matching due to the 
      // different values of these date properties.   
      const duplicatesRemovedValidSeats = validSeats.reduce((acc,seat)=>{
        if (acc.length) {

          // get list of duplicate entries 
          const duplicatesArray = acc.filter(acc_seat => acc_seat.userId === seat.userId)

          // if no duplicates are found mark as is not a duplicate and continue
          const isNotDuplicate = !(!!duplicatesArray.length)

          // validate if is not a duplicate
          if (isNotDuplicate) {
            // add to accumulator and return
            return [...acc, seat];
          } else {
            // return accumulator without duplicate seat added to it
            return acc;
          }
        } else {
          // add to accumulator and return
          return [seat];
        }
      },[])

    this.seatsUsed = duplicatesRemovedValidSeats.length
  };

  get SeatsRemaining() {
    return this.seatsPurchased - this.seatsUsed;
  }



  // <--- Flow --->
  async logout() {
    this.currentUser = { username: '' }
    window.parent.postMessage('logout', '*')
  };

  async loginJWT(token) {
    if (this.currentUser.id === null) {
      try {
        const user = await userFromToken(token);
        this.currentUser = user
      } catch(err) {
        console.log(err)
      }
    }
  };

  async getSubscription () {
    if (this.currentUser.subscriptions?.length) {
      this.subscription = this.currentUser.subscriptions[0];

      if (this.subscription.type === 'edu') {
        const {data} = await fetchSeats(this.subscription.id);
        this.activeSeats = data;
      }
    }
  }

  async changePassword(id, password, groupId) {
    this.changingPassword = true;
    this.changePasswordSuccess = undefined;
    this.changePasswordError = undefined;
    try {
      await updatePassword(id, password, groupId);
      this.changePasswordSuccess = "Password updated successfully.";
    } catch (err) {
      console.error(err);
      this.changePasswordError =
        "Password could not be updated. Perhaps you lack the permissions to do so? If you think this is a mistake, try again.";
    } finally {
      this.changingPassword = false;
    }
  };

  /**
   * Removes a seat record linked to the group creators subscription.
   * This is for use when a member is removed from a group and will effectively return the 
   * seat to the creator to be reassigned. Achieved by it no longer being present to be 
   * included in the remaining seat calculations when a member attempts to join a group 
   * created by this creator.
   * 
   * @param {Array<members>} members an array of members.
   */
  async removeSeats(members) {
    // define variable to be populated and used later in the function.
    let subId = undefined;

    // populate subId variable
    if (!!this.subscription.length) {
      // if subscription property is populated use it's id.
      subId = this.subscription.id;
    } else if (!!this.currentUser.subscriptions?.length) {
      // if subscription property is not populated, get id from first record of subscriptions property.
      subId = this.currentUser.subscriptions[0].id
    } else {
      // this should never happen however if it does this means no sub data has been gathered from the DB.
      // TODO: create/find a way for this to refresh the target properties.
    }

    // call on api call to request seat/seats be removed from the DB.
    removeSeatsApi(subId, members);
  };

};

class Profile {
  constructor(user) {
    makeAutoObservable(this)

    if (user) {
      const { firstName, lastName, username, email, id } = user
      this.originalValues = observable({
        name: `${firstName} ${lastName}`,
        username,
        email: email && email.length && email[0].email,
        id,
        emailId: email && email.length && email[0].emailId,
      })
    } else {
      this.originalValues = observable({
        username: "",
        email: ""
      })
    }
  }

  // <--- Observables --->
  isValid = {}
  isValidating = {}
  needsValidating = {}

  pastValid = {}
  pastInvalid = {}

  // <--- Actions --->\
  checkNeedsValidation(values) {
    Object.keys(values).forEach(key => {
      if (!this.pastValid[key]) {
        this.pastValid[key] = [];
      }
      if (!this.pastInvalid[key]) {
        this.pastInvalid[key] = [];
      }
      if (this.pastValid[key].includes(values[key])) {
        this.needsValidating = { ...this.needsValidating, [key]: false };
        this.isValid = { ...this.isValid, [key]: true };
      } else if (this.pastInvalid[key].includes(values[key])) {
        this.needsValidating = { ...this.needsValidating, [key]: false };
        this.isValid = { ...this.isValid, [key]: false };
      } else if (values[key] !== this.originalValues[key]) {
        this.isValid = { ...this.isValid, [key]: undefined };
        this.needsValidating = { ...this.needsValidating, [key]: true };
      } else {
        this.isValid = { ...this.isValid, [key]: undefined };
        this.needsValidating = { ...this.needsValidating, [key]: false };
      }
    });
  }

  // <--- Flow --->

  async validateUnique({ email, username }) {
    if (this.needsValidating.email) {
      this.isValidating = { ...this.isValidating, email: true };
      try {
        const valid = await validateUnique("email", email);
        if (valid) {
          this.pastValid.email = [...this.pastValid.email, email];
          this.isValid = { ...this.isValid, email: true };
        } else {
          this.pastInvalid.email = [...this.pastInvalid.email, email];
          this.isValid = { ...this.isValid, email: false };
        }
        this.needsValidating = { ...this.needsValidating, email: false };
      } catch (err) {
        console.error(err);
      } finally {
        this.isValidating = { ...this.isValidating, email: false };
      }
    }
    if (this.needsValidating.username) {
      try {
        this.isValidating = { ...this.isValidating, username: true };
        const valid = await validateUnique("username", username);
        if (valid) {
          this.pastValid.username = [...this.pastValid.username, username];
          this.isValid = { ...this.isValid, username: true };
        } else {
          this.pastInvalid.username = [...this.pastInvalid.username, username];
          this.isValid = { ...this.isValid, username: false };
        }
        this.needsValidating = { ...this.needsValidating, username: false };
      } catch (err) {
        console.error(err);
      } finally {
        this.isValidating = { ...this.isValidating, username: false };
      }
    }
  }

  async submit({ email, name, username }) {
    const {
      id,
      emailId,
      email: prevEmail,
      name: prevName,
      username: prevUsername,
    } = this.originalValues;
    try {
      if (email !== prevEmail) {
        await updateUserEmail(emailId, { value: email });
        this.originalValues.email = email;
      }
      if (username !== prevUsername || name !== prevName) {
        const [firstName = "", lastName = ""] = name.split(" ");
        await updateUser(id, { username, firstName, lastName });
        this.originalValues.username = username;
        this.originalValues.name = name;
      }
    } catch (err) {
      throw err;
    }
  }
}

export default UserStore;
